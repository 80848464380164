const PRISMA_PAE = 'prisma_access_edition';
const PRISMA_PA = 'prisma_access';
const SCM_APP_ID = 'strata_cloud_manager';
const STRATA_INSIGHTS = 'strata_insights';
const FORM_FACTOR_TYPE_NGFW = 'ngfw';
const FORM_FACTOR_TYPE_FLEX = 'flex';
const FORM_FACTOR_TYPE_PA = 'prisma_access';
const FORM_FACTOR_TYPE_CNGFW = 'cngfw';

import {
  SPARKY_NAV3_LEFT_NAV_EXTPOINT,
  SPARKY_NAV3_ROUTES_EXTPOINT,
} from '@sparky/framework/extpoints';


const isSCMPremium = (tsgInstances) => {
  for (const tsgInstance of tsgInstances) {
    // 2 conditions can result in SCM premium
    // FS Link: https://confluence-dc.paloaltonetworks.com/pages/viewpage.action?pageId=294784739#SCMPremiumActivation(NGFW,PrismaAccess,VMFlex)-Howtoidentifyproducttiers?
    // Check strata_cloud_manager app id and if its entitlements have NGFW or FLEX form factor then its premium
    // Check prisma_access_edition app id and if its entitlements have prisma_access form factor then its premium
    if (tsgInstance.app_id === SCM_APP_ID || tsgInstance.app_id === PRISMA_PAE) {
      if (tsgInstance.entitlements && tsgInstance.entitlements.length > 0) {
        for (const entitlement of tsgInstance.entitlements) {
          if (
            entitlement.app_id === SCM_APP_ID &&
            (entitlement.form_factor_type === FORM_FACTOR_TYPE_NGFW ||
              entitlement.form_factor_type === FORM_FACTOR_TYPE_FLEX ||
              entitlement.form_factor_type === FORM_FACTOR_TYPE_PA || 
              entitlement.form_factor_type === FORM_FACTOR_TYPE_CNGFW)
          ) {
            return true;
          }
        }
      }
    }
  }
  return false;
};

export default function ({ vars, sparkyFramework }) {
  return function tsgLoad({ auth, onTsgUnload }) {     
    let isSCMPremiumTenant = false;
    if (auth?.instances?.has(SCM_APP_ID)) {
      isSCMPremiumTenant = isSCMPremium(auth?.instances);
    } else {
      if (auth?.instances?.has(STRATA_INSIGHTS) || auth?.instances?.has(PRISMA_PA)) {
        isSCMPremiumTenant = true;
      }
    }

    if (isSCMPremiumTenant) {
      onTsgUnload(({ auth }) => { 
        return { requireReload: true } // always reload browser when switch TSG 
      })
      return { // can be not a function, functions rerun when force rerender
        navigation: () => [{
          key: 'manage',
          title: 'Manage',
          path: '/manage',
          mergeNav: true,
          expandable: true,
          children: [
            {
              key: 'security-posture',
              mergeNav: true,      
              path: '/manage/security-posture',
              title: 'Security Posture',
              expandable: true,
              children: [
                {
                  key: 'policy-analyzer',
                  path: `/manage/security-posture/policy-analyzer`,
                  title: 'Policy Analyzer',
                  noRBACheck: true,            
                  availFallbackRedirect: 'no-redirect',
                  isSetup: true,
                  mapStateToProps: ({auth}) =>  {                     
                    return { auth};
                  },
                  contentClassName: "panwds-tw3", 
                  component: () => import('./containers/PolicyAnalysis') 
                },
              ],
            },
          ],
        }]
      } 
    } else {
      return;
    }
  } 
 }


 // below is for nav v3 using extensions
 export const extensions = ({ sparkyFramework }) => {

  const leftNavExt = { // declare ext for left nav tree
      point: SPARKY_NAV3_LEFT_NAV_EXTPOINT,
      configs: [{
          id: 'panw.spiffy.nav.scm',
          config: {
              stateLoaders: ['auth'],
              navigation: () => [{
                  key: 'configuration',
                  path: '/configuration',
                  children: [{
                    key: 'posture',
                    title: 'Posture',
                    path: `/manage/security-posture`,
                    avail: () => true,
                    children: [{
                        key: 'policy-analyzer',
                        title: 'Policy Analyzer',
                        path: `/manage/security-posture/policy-analyzer`,
                        avail: () => true,
                      }],
                  }],
              }],
              isAllowed: () => true,
          }
      }]
  };

  const routesExt = { // declare ext for routes
      point: SPARKY_NAV3_ROUTES_EXTPOINT,
      configs: [{
          id: 'panw.spiffy.routes.policyAnalyzer',
          config: {
              path: '/manage/security-posture/policy-analyzer',
              // replacePaths: '/manage/security-posture/policy-analyzer',
              stateLoaders: ['auth'],
              // exact: false,
              mapStateToProps: ({auth}) =>  {                     
                return { auth};
              },     
              contentClassName: "panwds-tw3", // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
              component: () => import('./containers/PolicyAnalysis'), // any component must be dynamically import like this
              isAllowed: () => true,
          }
      }]
  };

  return [leftNavExt, routesExt] // return all extensions
}
